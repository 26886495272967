import React from "react"
import aight from "../images/aight.png"

const IndexPage = () => (
  <div>
    <img
      alt="itsmikeaight qrcode"
      style={{
        display: "block",
        width: "100%",
        maxWidth: "300px",
        margin: "auto",
      }}
      src={aight}
    />
  </div>
);

export default IndexPage
